html {
  margin: 0;
  padding: 0;
}

.imagen-header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 25rem;
}
.reproductor {
  width: 100% !important;
}
.oscure {
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 1rem;
}
.space{
  min-height: calc(100vh - 30rem);
}
.text-gris-hdco{
  background-color:#d1d1d1!important;
}
